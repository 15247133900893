// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Breadcrumb from "../../../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as SearchField from "../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as Routes_Location from "../../../../../routes/common/Routes_Location.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexHeaderCss from "./LocationsIndexHeaderCss.res.js";
import * as Outline from "@heroicons/react/24/outline";

function LocationsIndexHeader(props) {
  var breadcrumbs = props.breadcrumbs;
  var breadcrumbs$1 = breadcrumbs !== undefined ? breadcrumbs : [
      {
        linkName: "Home",
        linkPath: "/"
      },
      {
        linkName: "All Data Centers",
        linkPath: Routes_Location.index
      }
    ];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Breadcrumb.make, {
                              breadcrumbLinks: breadcrumbs$1,
                              wrapperContainerClassName: LocationsIndexHeaderCss.Title.breadcrumbs
                            }),
                        JsxRuntime.jsxs("h1", {
                              children: [
                                props.title + " ",
                                JsxRuntime.jsx("span", {
                                      children: "(" + String(props.locationsCount) + ")",
                                      className: LocationsIndexHeaderCss.Title.count
                                    })
                              ],
                              className: LocationsIndexHeaderCss.Title.title
                            })
                      ],
                      className: LocationsIndexHeaderCss.Title.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Reset"
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Outline.ArrowPathIcon, {
                                            className: LocationsIndexHeaderCss.Filters.Icon.base
                                          }),
                                      title: "Reset Filters"
                                    })
                              ],
                              className: Cx.cx([
                                    LocationsIndexHeaderCss.Filters.Icon.container,
                                    props.resetDisabled ? LocationsIndexHeaderCss.Filters.Icon.disabled : LocationsIndexHeaderCss.Filters.Icon.enabled
                                  ]),
                              onClick: props.reset
                            }),
                        JsxRuntime.jsx(SearchField.make, {
                              id: "locations-search",
                              value: props.search,
                              placeholder: "Search locations",
                              inputClassName: LocationsIndexHeaderCss.Filters.search,
                              onChange: props.onChange
                            })
                      ],
                      className: LocationsIndexHeaderCss.Filters.container
                    })
              ],
              className: LocationsIndexHeaderCss.container
            });
}

var Css;

var make = LocationsIndexHeader;

export {
  Css ,
  make ,
}
/* Breadcrumb Not a pure module */
