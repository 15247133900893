// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Cloudinary from "../../../../../libs/Cloudinary.res.js";
import * as ResponseMessage from "../../../../../styleguide/forms/ResponseMessage/ResponseMessage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Global from "Client/common/global";
import * as LocationsIndexMapCss from "./LocationsIndexMapCss.res.js";
import * as LocationsIndexMapTile from "./components/LocationsIndexMapTile.res.js";
import * as ReactGoogleMaps from "@vis.gl/react-google-maps";

var pin = Cloudinary.imageUrl("f_auto,w_46,h_46/static/maps/cluster-purple.png");

function LocationsIndexMap(props) {
  var __defaultZoom = props.defaultZoom;
  var __longitude = props.longitude;
  var __latitude = props.latitude;
  var userRole = props.userRole;
  var preloadedSearchLocations = props.preloadedSearchLocations;
  var latitude = __latitude !== undefined ? __latitude : 25.0000;
  var longitude = __longitude !== undefined ? __longitude : -70.0000;
  var defaultZoom = __defaultZoom !== undefined ? __defaultZoom : 3;
  var x = preloadedSearchLocations.length;
  return JsxRuntime.jsxs("div", {
              children: [
                x !== 0 ? (
                    x === 1000 ? JsxRuntime.jsx(ResponseMessage.make, {
                            messageType: "Warning",
                            children: "Only showing the first 1,000 data centers. Refine your search to get better results."
                          }) : null
                  ) : JsxRuntime.jsx(ResponseMessage.make, {
                        messageType: "Info",
                        children: "No records were found. Refine your search to get better results."
                      }),
                JsxRuntime.jsx(ReactGoogleMaps.APIProvider, {
                      apiKey: Global.GoogleMapAPI,
                      children: JsxRuntime.jsx(ReactGoogleMaps.Map, {
                            defaultCenter: {
                              lat: latitude,
                              lng: longitude
                            },
                            defaultZoom: defaultZoom,
                            mapId: Js_array.joinWith("__", [
                                  props.mapId,
                                  "LocationsIndexMap"
                                ]),
                            colorScheme: "LIGHT",
                            reuseMap: true,
                            children: Belt_Array.mapWithIndex(preloadedSearchLocations, (function (index, $$location) {
                                    return JsxRuntime.jsx(LocationsIndexMapTile.make, {
                                                pin: pin,
                                                preloadedSearchLocation: $$location,
                                                userRole: userRole
                                              }, String(index));
                                  }))
                          })
                    })
              ],
              className: LocationsIndexMapCss.container
            });
}

var Maps;

var Css;

var make = LocationsIndexMap;

export {
  Maps ,
  Css ,
  pin ,
  make ,
}
/* pin Not a pure module */
