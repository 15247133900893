// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as Outline from "@heroicons/react/24/outline";
import * as LocationsIndexFetchResultsCss from "./LocationsIndexFetchResultsCss.res.js";

function LocationsIndexFetchResults(props) {
  var tmp;
  switch (props.status) {
    case "Fetching" :
        tmp = JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Outline.GlobeAltIcon, {
                      className: LocationsIndexFetchResultsCss.Alert.Fetching.icon
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Searching Locations",
                              className: LocationsIndexFetchResultsCss.Alert.Fetching.title
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Looking for locations based on your search criteria.",
                              className: LocationsIndexFetchResultsCss.Alert.Fetching.description
                            })
                      ],
                      className: LocationsIndexFetchResultsCss.Alert.explanation
                    })
              ],
              className: LocationsIndexFetchResultsCss.Alert.Fetching.container
            });
        break;
    case "Empty" :
        tmp = JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Outline.DocumentMagnifyingGlassIcon, {
                      className: LocationsIndexFetchResultsCss.Alert.Empty.icon
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "No Locations Found",
                              className: LocationsIndexFetchResultsCss.Alert.Empty.title
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Update your filters or click here to reset your search.",
                              className: LocationsIndexFetchResultsCss.Alert.Empty.description
                            })
                      ],
                      className: LocationsIndexFetchResultsCss.Alert.explanation
                    })
              ],
              className: LocationsIndexFetchResultsCss.Alert.Empty.container
            });
        break;
    case "Error" :
        tmp = JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Outline.ExclamationTriangleIcon, {
                      className: LocationsIndexFetchResultsCss.Alert.$$Error.icon
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Error Fetching Locations",
                              className: LocationsIndexFetchResultsCss.Alert.$$Error.title
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Something went wrong while fetching locations. Please click here to reset your search.",
                              className: LocationsIndexFetchResultsCss.Alert.$$Error.description
                            })
                      ],
                      className: LocationsIndexFetchResultsCss.Alert.explanation
                    })
              ],
              className: LocationsIndexFetchResultsCss.Alert.$$Error.container
            });
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: LocationsIndexFetchResultsCss.container,
              onClick: props.reset
            });
}

var Css;

var make = LocationsIndexFetchResults;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
