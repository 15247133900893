// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "p-7",
      "xl:px-16",
      "h-300"
    ]);

var container$1 = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "items-center",
      "w-[280px]",
      "font-[400]",
      "rounded",
      "hover:cursor-pointer"
    ]);

var pin = Cx.cx([
      "flex",
      "items-center",
      "size-6",
      "rounded-full",
      "hover:size-8"
    ]);

var details = Cx.cx([
      "flex",
      "flex-col",
      "gap-1"
    ]);

var providerName = Cx.cx([
      "text-[14px]",
      "text-gray-500"
    ]);

var name = Cx.cx([
      "text-[18px]",
      "font-medium",
      "hover:text-purple"
    ]);

var sponsored = Cx.cx([
      "uppercase",
      "italic",
      "text-gray-500",
      "text-[14px]"
    ]);

var container$2 = Cx.cx([
      "relative",
      "w-full",
      "rounded",
      "overflow-hidden"
    ]);

var picture = Cx.cx([
      "w-full",
      "rounded"
    ]);

var logo = Cx.cx([
      "absolute",
      "flex",
      "items-center",
      "justify-center",
      "top-2",
      "left-2",
      "rounded",
      "bg-white",
      "p-2",
      "w-24",
      "shadow-md",
      "shadow-gray-500/20"
    ]);

var agreement = Cx.cx([
      "absolute",
      "top-2",
      "right-2",
      "text-green-600",
      "size-6"
    ]);

var $$Image = {
  container: container$2,
  picture: picture,
  logo: logo,
  agreement: agreement
};

var InfoWindow = {
  container: container$1,
  pin: pin,
  details: details,
  providerName: providerName,
  name: name,
  sponsored: sponsored,
  fullAddress: providerName,
  $$Image: $$Image
};

export {
  container ,
  InfoWindow ,
}
/* container Not a pure module */
