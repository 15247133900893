// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "hidden",
      "sm:flex",
      "sm:justify-between",
      "sm:pt-7",
      "sm:px-7",
      "xl:px-16"
    ]);

export {
  container ,
}
/* container Not a pure module */
