// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Outline from "@heroicons/react/24/outline";
import * as LocationsIndexViewButtonsCss from "./LocationsIndexViewButtonsCss.res.js";

function LocationsIndexViewButtons(props) {
  var updateView = props.updateView;
  var view = props.view;
  return JsxRuntime.jsxs("span", {
              children: [
                JsxRuntime.jsxs("button", {
                      children: [
                        JsxRuntime.jsx(Outline.Squares2X2Icon, {
                              className: LocationsIndexViewButtonsCss.Button.icon
                            }),
                        "Grid"
                      ],
                      className: Cx.cx([
                            LocationsIndexViewButtonsCss.Button.left,
                            view === "Grid" ? LocationsIndexViewButtonsCss.Button.active : LocationsIndexViewButtonsCss.Button.inactive
                          ]),
                      onClick: (function (param) {
                          if (view !== "Grid") {
                            return updateView("Grid");
                          }
                          
                        })
                    }),
                JsxRuntime.jsxs("button", {
                      children: [
                        JsxRuntime.jsx(Outline.GlobeAltIcon, {
                              className: LocationsIndexViewButtonsCss.Button.icon
                            }),
                        "On Map"
                      ],
                      className: Cx.cx([
                            LocationsIndexViewButtonsCss.Button.right,
                            view === "Map" ? LocationsIndexViewButtonsCss.Button.active : LocationsIndexViewButtonsCss.Button.inactive
                          ]),
                      onClick: (function (param) {
                          if (view !== "Map") {
                            return updateView("Map");
                          }
                          
                        })
                    })
              ],
              className: LocationsIndexViewButtonsCss.container
            });
}

var Css;

var make = LocationsIndexViewButtons;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
