// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "gap-4",
      "border-b",
      "border-gray-100",
      "sm:p-7",
      "lg:gap-0",
      "lg:flex-row",
      "lg:justify-between",
      "lg:items-center",
      "xl:px-16"
    ]);

var container$1 = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "justify-center"
    ]);

var title = Cx.cx([
      "text-2xl",
      "sm:xl",
      "font-semibold"
    ]);

var count = Cx.cx([
      title,
      "text-gray-400",
      "font-medium"
    ]);

var breadcrumbs = Cx.cx([
      "flex!",
      "text-center!",
      "m-0!"
    ]);

var Title = {
  container: container$1,
  title: title,
  count: count,
  breadcrumbs: breadcrumbs
};

var container$2 = Cx.cx([
      "flex",
      "flex-col-reverse",
      "gap-2",
      "pb-7",
      "sm:flex-row-reverse",
      "sm:justify-end",
      "sm:pb-0",
      "sm:items-center",
      "lg:flex-row"
    ]);

var container$3 = Cx.cx([
      "flex",
      "gap-2",
      "items-center"
    ]);

var enabled = Cx.cx([
      "text-gray",
      "hover:text-teal",
      "cursor-pointer"
    ]);

var Icon = {
  container: container$3,
  base: "size-5",
  disabled: "text-gray-100",
  enabled: enabled
};

var search = Cx.cx([
      "w-full",
      "sm:w-70!"
    ]);

var Filters = {
  container: container$2,
  Icon: Icon,
  search: search
};

var body = Cx.cx([
      "flex",
      "flex-col",
      "gap-3",
      "w-full",
      "sm:w-62!",
      "text-sm!",
      "max-h-62",
      "overflow-y-auto"
    ]);

var row = Cx.cx([
      "grid",
      "grid-cols-10"
    ]);

var providerName = Cx.cx([
      "col-span-9",
      "-mt-1!"
    ]);

var Dropdown = {
  body: body,
  row: row,
  trigger: "w-44!",
  checkbox: "col-span-1",
  providerName: providerName
};

export {
  container ,
  Title ,
  Filters ,
  Dropdown ,
}
/* container Not a pure module */
