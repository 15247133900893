// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Control from "../../../../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../../../../styleguide/components/Popover/Popover.res.js";
import * as Dropdown from "../../../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Solid from "@heroicons/react/24/solid";
import * as LocationsIndexSortFiltersCss from "../LocationsIndexSortFiltersCss.res.js";

function LocationsIndexSortFiltersProvider(props) {
  var sort = props.sort;
  var sortBy = props.sortBy;
  var variant = sortBy.NAME;
  var variant$1 = sortBy.NAME;
  var variant$2 = sortBy.NAME;
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsxs(Control.make, {
                            className: LocationsIndexSortFiltersCss.control,
                            children: [
                              JsxRuntime.jsx(Solid.Bars3BottomRightIcon, {
                                    className: sortBy.NAME === "Sponsored" || sortBy.VAL !== "Desc" ? LocationsIndexSortFiltersCss.icon : LocationsIndexSortFiltersCss.iconInverse
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: variant === "StateName" ? (
                                        sortBy.VAL === "Desc" ? "State Z-A" : "State A-Z"
                                      ) : (
                                        variant === "Sponsored" || variant === "CountryName" ? "Select a Sort" : (
                                            variant === "ProviderName" ? (
                                                sortBy.VAL === "Desc" ? "Provider Z-A" : "Provider A-Z"
                                              ) : (
                                                variant === "Popular" ? (
                                                    sortBy.VAL === "Desc" ? "Most Popular" : "Select a Sort"
                                                  ) : (
                                                    sortBy.VAL === "Desc" ? "City Z-A" : "City A-Z"
                                                  )
                                              )
                                          )
                                      )
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsxs(Dropdown.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "LeftEdge"
                      },
                      className: LocationsIndexSortFiltersCss.Dropdown.body,
                      children: [
                        JsxRuntime.jsx(Control.make, {
                              children: JsxRuntime.jsx("div", {
                                    children: "Sort Options",
                                    className: LocationsIndexSortFiltersCss.Dropdown.title
                                  })
                            }),
                        JsxRuntime.jsx(Control.make, {
                              onClick: sort({
                                    NAME: "Popular",
                                    VAL: "Desc"
                                  }),
                              children: JsxRuntime.jsx("div", {
                                    children: "Most Popular",
                                    className: sortBy.NAME === "Popular" && sortBy.VAL === "Desc" ? LocationsIndexSortFiltersCss.Dropdown.linkSelected : LocationsIndexSortFiltersCss.Dropdown.link
                                  })
                            }),
                        JsxRuntime.jsx(Control.make, {
                              onClick: sort({
                                    NAME: "CityName",
                                    VAL: "Asc"
                                  }),
                              children: JsxRuntime.jsx("div", {
                                    children: "City A-Z",
                                    className: variant$1 === "Popular" || variant$1 === "ProviderName" || variant$1 === "Sponsored" || variant$1 === "CountryName" || variant$1 === "StateName" || sortBy.VAL === "Desc" ? LocationsIndexSortFiltersCss.Dropdown.link : LocationsIndexSortFiltersCss.Dropdown.linkSelected
                                  })
                            }),
                        JsxRuntime.jsx(Control.make, {
                              onClick: sort({
                                    NAME: "CityName",
                                    VAL: "Desc"
                                  }),
                              children: JsxRuntime.jsx("div", {
                                    children: "City Z-A",
                                    className: variant$2 === "Popular" || variant$2 === "ProviderName" || variant$2 === "Sponsored" || variant$2 === "CountryName" || variant$2 === "StateName" || sortBy.VAL !== "Desc" ? LocationsIndexSortFiltersCss.Dropdown.link : LocationsIndexSortFiltersCss.Dropdown.linkSelected
                                  })
                            }),
                        JsxRuntime.jsx(Control.make, {
                              onClick: sort({
                                    NAME: "StateName",
                                    VAL: "Asc"
                                  }),
                              children: JsxRuntime.jsx("div", {
                                    children: "State A-Z",
                                    className: sortBy.NAME === "StateName" && sortBy.VAL !== "Desc" ? LocationsIndexSortFiltersCss.Dropdown.linkSelected : LocationsIndexSortFiltersCss.Dropdown.link
                                  })
                            }),
                        JsxRuntime.jsx(Control.make, {
                              onClick: sort({
                                    NAME: "StateName",
                                    VAL: "Desc"
                                  }),
                              children: JsxRuntime.jsx("div", {
                                    children: "State Z-A",
                                    className: sortBy.NAME === "StateName" && sortBy.VAL === "Desc" ? LocationsIndexSortFiltersCss.Dropdown.linkSelected : LocationsIndexSortFiltersCss.Dropdown.link
                                  })
                            })
                      ]
                    })
              ]
            });
}

var Css;

var make = LocationsIndexSortFiltersProvider;

export {
  Css ,
  make ,
}
/* Control Not a pure module */
