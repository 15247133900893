// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexSortFiltersCss from "./LocationsIndexSortFiltersCss.res.js";
import * as LocationsIndexSortFiltersCity from "./components/LocationsIndexSortFiltersCity.res.js";
import * as LocationsIndexSortFiltersState from "./components/LocationsIndexSortFiltersState.res.js";
import * as LocationsIndexSortFiltersCountry from "./components/LocationsIndexSortFiltersCountry.res.js";
import * as LocationsIndexSortFiltersGeneral from "./components/LocationsIndexSortFiltersGeneral.res.js";
import * as LocationsIndexSortFiltersProvider from "./components/LocationsIndexSortFiltersProvider.res.js";

function LocationsIndexSortFilters(props) {
  var sort = props.sort;
  var __indexType = props.indexType;
  var sortBy = props.sortBy;
  var indexType = __indexType !== undefined ? __indexType : "General";
  var tmp;
  switch (indexType) {
    case "General" :
        tmp = JsxRuntime.jsx(LocationsIndexSortFiltersGeneral.make, {
              sortBy: sortBy,
              sort: sort
            });
        break;
    case "Country" :
        tmp = JsxRuntime.jsx(LocationsIndexSortFiltersCountry.make, {
              sortBy: sortBy,
              sort: sort
            });
        break;
    case "State" :
        tmp = JsxRuntime.jsx(LocationsIndexSortFiltersState.make, {
              sortBy: sortBy,
              sort: sort
            });
        break;
    case "City" :
        tmp = JsxRuntime.jsx(LocationsIndexSortFiltersCity.make, {
              sortBy: sortBy,
              sort: sort
            });
        break;
    case "Provider" :
        tmp = JsxRuntime.jsx(LocationsIndexSortFiltersProvider.make, {
              sortBy: sortBy,
              sort: sort
            });
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: tmp,
                          title: "Sort options"
                        }),
                    className: LocationsIndexSortFiltersCss.controls
                  }),
              className: LocationsIndexSortFiltersCss.container
            });
}

var Css;

var make = LocationsIndexSortFilters;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
