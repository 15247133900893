// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Picture from "../../../../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Location from "../../../../../../models/Location.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexMapCss from "../LocationsIndexMapCss.res.js";
import * as Solid from "@heroicons/react/24/solid";
import * as ReactGoogleMaps from "@vis.gl/react-google-maps";

function LocationsIndexMapTile(props) {
  var preloadedSearchLocation = props.preloadedSearchLocation;
  var match = React.useState(function () {
        return false;
      });
  var setInfoWindowOpen = match[1];
  var match$1 = ReactGoogleMaps.useAdvancedMarkerRef();
  var tmp;
  if (match[0]) {
    var tmp$1;
    var exit = 0;
    switch (props.userRole) {
      case "Admin" :
      case "Concierge" :
      case "Agent" :
          exit = 1;
          break;
      default:
        tmp$1 = null;
    }
    if (exit === 1) {
      tmp$1 = preloadedSearchLocation.providerAgreement ? JsxRuntime.jsx(Solid.CheckBadgeIcon, {
              className: LocationsIndexMapCss.InfoWindow.$$Image.agreement,
              strokeWidth: 1,
              stroke: "white"
            }) : null;
    }
    tmp = JsxRuntime.jsx(ReactGoogleMaps.InfoWindow, {
          anchor: match$1[1],
          onCloseClick: (function () {
              setInfoWindowOpen(function (param) {
                    return false;
                  });
            }),
          maxWidth: 310,
          children: Caml_option.some(JsxRuntime.jsxs("a", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Picture.make, {
                                          src: preloadedSearchLocation.providerLogo,
                                          large: [
                                            80,
                                            50
                                          ],
                                          crop: "Fit",
                                          className: ""
                                        }),
                                    className: LocationsIndexMapCss.InfoWindow.$$Image.logo
                                  }),
                              tmp$1,
                              JsxRuntime.jsx(Picture.make, {
                                    src: preloadedSearchLocation.mainImageUrl,
                                    large: [
                                      280,
                                      170
                                    ],
                                    className: LocationsIndexMapCss.InfoWindow.$$Image.picture,
                                    fallbackSrc: $$Location.imageNotFoundUrl
                                  })
                            ],
                            className: LocationsIndexMapCss.InfoWindow.$$Image.container
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: preloadedSearchLocation.providerName,
                                    className: LocationsIndexMapCss.InfoWindow.providerName
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: preloadedSearchLocation.name,
                                    className: LocationsIndexMapCss.InfoWindow.name
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: preloadedSearchLocation.fullAddress,
                                    className: LocationsIndexMapCss.InfoWindow.fullAddress
                                  }),
                              preloadedSearchLocation.sponsoredCity ? JsxRuntime.jsx("div", {
                                      children: "Sponsored",
                                      className: LocationsIndexMapCss.InfoWindow.sponsored
                                    }) : null
                            ],
                            className: LocationsIndexMapCss.InfoWindow.details
                          })
                    ],
                    className: LocationsIndexMapCss.InfoWindow.container,
                    href: preloadedSearchLocation.url,
                    target: "_blank"
                  }))
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(ReactGoogleMaps.AdvancedMarker, {
              ref: match$1[0],
              position: {
                lat: preloadedSearchLocation.latitude,
                lng: preloadedSearchLocation.longitude
              },
              clickable: true,
              children: [
                JsxRuntime.jsx("img", {
                      className: LocationsIndexMapCss.InfoWindow.pin,
                      src: props.pin,
                      onClick: (function (param) {
                          setInfoWindowOpen(function (param) {
                                return true;
                              });
                        })
                    }),
                tmp
              ]
            });
}

var Css;

var Maps;

var make = LocationsIndexMapTile;

export {
  Css ,
  Maps ,
  make ,
}
/* react Not a pure module */
