// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "text-gray",
      "flex",
      "justify-between",
      "items-center"
    ]);

var controls = Cx.cx([
      "flex",
      "gap-2"
    ]);

var control = Cx.cx([
      "flex",
      "gap-2",
      "hover:text-teal"
    ]);

var icon = Cx.cx([
      "size-6",
      "hover:text-teal",
      "cursor-pointer"
    ]);

var iconInverse = Cx.cx([
      icon,
      "transform",
      "scale-y-[-1]"
    ]);

var body = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "w-40"
    ]);

var title = Cx.cx([
      "font-semibold",
      "text-gray-700"
    ]);

var link = Cx.cx([
      "text-gray",
      "hover:text-teal"
    ]);

var linkSelected = Cx.cx([
      "text-purple",
      "font-semibold",
      "hover:text-teal"
    ]);

var Dropdown = {
  body: body,
  title: title,
  link: link,
  linkSelected: linkSelected
};

export {
  container ,
  controls ,
  control ,
  icon ,
  iconInverse ,
  Dropdown ,
}
/* container Not a pure module */
