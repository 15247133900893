// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "gap-8",
      "py-7",
      "border-b",
      "border-gray-300",
      "p-7",
      "xl:px-16",
      "@container"
    ]);

var container$1 = Cx.cx([
      "grid",
      "grid-cols-1",
      "gap-4",
      "@sm:grid-cols-2",
      "@lg:grid-cols-3",
      "@4xl:grid-cols-4",
      "@7xl:grid-cols-5"
    ]);

var Locations = {
  container: container$1
};

var container$2 = Cx.cx([
      "flex",
      "flex-col",
      "gap-4",
      "items-center",
      "text-sm",
      "text-gray",
      "sm:flex-row",
      "sm:justify-between"
    ]);

var Pagination = {
  container: container$2
};

export {
  container ,
  Locations ,
  Pagination ,
}
/* container Not a pure module */
