// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexSortFilters from "./components/sort/LocationsIndexSortFilters.res.js";
import * as LocationsIndexViewButtons from "./components/view-buttons/LocationsIndexViewButtons.res.js";
import * as LocationsIndexSubheaderCss from "./LocationsIndexSubheaderCss.res.js";

function LocationsIndexSubheader(props) {
  var __indexType = props.indexType;
  var indexType = __indexType !== undefined ? __indexType : "General";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LocationsIndexSortFilters.make, {
                      sortBy: props.sortBy,
                      indexType: indexType,
                      sort: props.sort
                    }),
                JsxRuntime.jsx(LocationsIndexViewButtons.make, {
                      view: props.view,
                      updateView: props.updateView
                    })
              ],
              className: LocationsIndexSubheaderCss.container
            });
}

var Css;

var make = LocationsIndexSubheader;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
